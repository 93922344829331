import { responsive } from '@common/constants'
import BookFreeClassButton from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { VideoTestimonialType } from '@lib/data/summer-coding-camp'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

type VideoTestimonialProps = {
  title: string | JSX.Element | React.ReactNode
  video_testimonial: VideoTestimonialType[]
  isSummerCampPage?: boolean
  urlQuery?: string
  displayCTA?: boolean
}

// Extend the IframeHTMLAttributes interface
declare module 'react' {
  interface IframeHTMLAttributes<T> extends React.HTMLAttributes<T> {
    loading?: 'lazy' | 'eager' | 'auto'
  }
}

const VideoCard = ({ testimonial }: { testimonial: VideoTestimonialType }) => {
  return (
    <div className="space-y-2 text-center px-2">
      <div className="relative" style={{ height: '340px', width: '255px' }}>
        <iframe
          loading="lazy"
          src={testimonial.video}
          className="border-none absolute top-0 h-full w-full"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        ></iframe>
      </div>
      {testimonial.name && (
        <p className="font-600 text-2xl">
          <MsgTrans msg={testimonial.name} />
        </p>
      )}
      {testimonial.description && (
        <p className="text-sm">
          <MsgTrans msg={testimonial.description} />
        </p>
      )}
      {testimonial.type && (
        <p className="text-sm text-grey-800">
          <MsgTrans msg={testimonial.type} />
        </p>
      )}
    </div>
  )
}

const VideoTestimonial = ({
  title,
  video_testimonial,
  isSummerCampPage,
  urlQuery,
  displayCTA,
}: VideoTestimonialProps) => {
  return (
    <div className="container mx-auto flex flex-col space-y-10 leading-tight pt-12 text-grey px-5">
      <div className="text-center">
        <h2 className="text-2xl lg:text-4xl font-600 mb-4 max-w-4xl mx-auto">
          {title}
        </h2>
      </div>

      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={true}
        dotListClass="mt-10"
        sliderClass="px-10"
        containerClass="custom-corousel-container"
      >
        {video_testimonial.map((testimonial, index) => (
          <div className="slider flex justify-center mb-10" key={index}>
            <VideoCard testimonial={testimonial} />
          </div>
        ))}
      </Carousel>

      {isSummerCampPage && (
        <div className="flex justify-center pt-8 pb-16">
          <a
            className="btn btn-orange w-full md:w-auto py-3 text-lg"
            href="#courses"
          >
            Claim your spot now
          </a>
        </div>
      )}
      {displayCTA && (
        <div className="flex justify-center pt-8">
          <BookFreeClassButton size="xl" query={urlQuery} />
        </div>
      )}
    </div>
  )
}

export default VideoTestimonial
